.btn {
  &-inherit-color {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &-condensed {
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  &-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: none; // override bootstrap _reboot.scss - apple webkit bug #110427
}
