//
// Colors
// ========================================

$aqua: #36a5b2;
$black: #000;
$blue-dark: #27396b;
$blue-gray: #6a777f;
$blue: #146cb1;
$brown: #a3715a;
$gray1: #2e3941;
$gray2: #b2b2b2;
$gray3: #d8d8d8;
$gray4: #f6f6f6;
$green: #31750e;
$orange: #fa8e1e;
$purple: #7358a0;
$red: #cb3732;
$teal: #00947a;
$white: #fff;
$yellow: #edb225;

//
// Bootstrap Overrides
// ========================================

$body-bg: #eee;
$link-color: $blue;
$input-bg: $gray4;
$input-border: $gray3;
$input-color: $black;
$info: $blue;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';


//
// Forms
// ========================================

$control-width-xs: 80px;
$control-width-sm: 150px;
$control-width-md: 300px;


//
// Media Query Variables
// ========================================

$m: 'only screen and (max-width: 767px)';
$t: 'only screen and (min-width: 768px) and (max-width: 992px)';
$t-up: 'only screen and (min-width: 768px)';
$t-down: 'only screen and (max-width: 992px)';
$d: 'only screen and (min-width: 993px)';
$xl: 'only screen and (min-width: 1200px)';

//
// Custom
// ========================================

$modal-z-index: 500;
$enable-negative-margins: true;