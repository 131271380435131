$alert-dark-bg: $blue-dark;
$alert-dark-color: $white;
$alert-danger-bold-bg: $red;
$alert-danger-bold-color: $white;
$alert-success-bold-bg: $green;
$alert-success-bold-color: $white;

.alert {
  &-dark {
    background-color: $alert-dark-bg;
    color: $alert-dark-color;
  }

  &-danger-bold {
    background-color: $alert-danger-bold-bg;
    color: $alert-danger-bold-color;
  }

  &-success-bold {
    background-color: $alert-success-bold-bg;
    color: $alert-success-bold-color;
  }
}
