.modal {
  // seems to be a bug in ngx-bootstrap where the `.in` class is not being applied
  &.fade.show {
    // sass-lint:disable-line force-element-nesting
    opacity: 1;

    & > .modal-dialog {
      transform: translate(0, 0);

      // Special handler for iphone
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        transform: translate(0, 50%);
      }
    }
  }

  // Special handler for iphone
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    transform: scale(0.9);
  }
}

.modal-title {
  font-size: 1.3rem;
}
