@import './sass/variables';

@import '../assets/css/core';

$home-form-border-color: #d8d8d8;

.logo,
.logo__wrapper > img {
  height: 40px;
}

.signup {
  background: url('../assets/img/graphic-home.svg') no-repeat 50% 0;
  background-size: 50%;
  display: block;

  @media #{$m} {
    background: none;
  }
}

.home-form {
  background-color: $white;
  border: 1px solid $home-form-border-color;
  border-radius: 10px;
  margin: 3em 0;
  padding: 2em 4em;

  @media #{$t} {
    padding: 1em 2em;
  }

  @media #{$m} {
    border: 0;
    margin-top: 0;
    padding: 1em;
  }

  legend {
    @extend .header-2;
    border: 0;
    color: $green;
    text-align: center;
  }
}

.container--signup {
  margin-top: calc(2vw * 6);

  @media #{$m} {
    margin-top: 0;
  }
}

.dashboard-search {
  align-items: center;
  display: flex;

  &__item {
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.dashboard-container {
  padding: 2rem;

  &--constrained-sidebar {
    @media #{$t-up} {
      max-width: calc(100vw - 315px);
    }
  }
}

.dashboard {
  display: flex;
  height: 100%;
  width: 100%;
}

.dashboard-main {
  padding: 2rem;
  width: 100%;
}

.db-dl {
  flex: 1 1 33.33%;
  flex-grow: 1;
  margin-right: 1rem;

  &__dt {
    color: $gray2;
    font-size: 0.75em;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
