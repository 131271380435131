// sass-lint:disable no-important

//
// Buttons / Links
// ========================================

.link {
  background: none;
  border: 0;
  display: inline;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

//
// Cursor
// ========================================

.cursor-pointer {
  &:not(.disabled) {
    &:not([disabled]) {
      cursor: pointer;
    }
  }
}

//
// Colors
// ========================================

.text {
  &-green {
    color: $green !important;
  }

  &-blue {
    color: $blue !important;
  }

  &-red {
    color: $red !important;
  }

  &-dark {
    color: $gray1 !important;
  }

  &-gray {
    color: $gray2 !important;
  }

  &-orange {
    color: $orange !important;
  }

  &-white {
    color: $white !important;
  }
}

//
// Phone number control
// ========================================

.iti {
  width: 300px;

  @media only screen and (max-width: 576px) {
    width: 196px;
  }
}

//
// Cannot longer edit if submit warning
// ========================================
.warning-text {
  font-size: 1.1rem;
  font-weight: 600;
  font-style: italic;
}