.main-content {
  padding: 2rem;
  width: 100%;

  &--constrained {
    max-width: 1280px;
  }

  @media only screen and (max-width: 992px) {
    transform: scale(0.9);
    padding: 0 2%;
    width: 96%;
  }
}

.hr-dark {
  border-color: $gray3;
}
