@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

html {
  font-size: 16px;

  @media #{$t-up} {
    font-size: 14px;
  }
}

html,
body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.header {
  &-1 {
    color: $gray1;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1.06px;
    line-height: 1.5em;
    margin: 0 0 1em;
    text-transform: uppercase;
  }
}
