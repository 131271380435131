.input,
.textarea,
.select {
  // extend Bootstrap, to avoid direct use in markup
  @extend .form-control;
  box-shadow: none;

  @media #{$t-up} {
    &--width-xs {
      width: $control-width-xs;
    }

    &--width-sm {
      width: $control-width-sm;
    }

    &--width-md {
      width: $control-width-md;
    }

    &--width-auto {
      width: auto;
    }

    &--width-full {
      width: 100%;
    }
  }
}

fieldset {
  // override Firefox default
  display: block;
}
